import React from 'react';

import { getElementByKey, getPercents, numberWithCommas } from 'utils';

import OneLineIndicator from '../OneLineIndicator';

const EmployeesLineIndicator = ({ data, keyName }) => {
	const employeesCount = getElementByKey(data, 'total');

	const labelsKeyMap = {
		female: 'Female',
		male: 'Male',
		long_term: 'Long term',
		short_term: 'Short term',
		national: 'National',
		international: 'International',
	};

	const dataSet = getElementByKey(data, keyName, {});
	return Object.keys(dataSet).map((key) => {
		const label = getElementByKey(labelsKeyMap, key);

		// only available data
		if (!label) return null;

		// build value
		const valueSet = dataSet[key];
		const value = getElementByKey(valueSet, 'value', 0);
		const percent = getElementByKey(valueSet, 'percent', 0);
		const displayedValue = `${numberWithCommas(value)} / ${percent || 0}%`;

		const gender = getElementByKey(valueSet, 'gender');
		const male = getElementByKey(gender, 'male');
		const female = getElementByKey(gender, 'female');

		return (
			<div key={key}>
				<OneLineIndicator
					label={label}
					value={employeesCount ? displayedValue : 'N/A'}
					className="employees-indicators__indicator"
				/>
				{employeesCount ? (
					<div className="sub-indicators">
						<div>
							Female&nbsp;
							{getElementByKey(female, 'percent')}% (
							{numberWithCommas(getElementByKey(female, 'value'))}),&nbsp;
						</div>
						<div>
							Male&nbsp;
							{getElementByKey(male, 'percent')}% (
							{numberWithCommas(getElementByKey(male, 'value'))})
						</div>
					</div>
				) : (
					''
				)}
			</div>
		);
	});
};

const EmployeesIndicators = ({ data }) => {
	const employeesCount = getElementByKey(data, 'total');
	const international = getElementByKey(
		data?.national_international,
		'international',
		{},
	);
	const national = getElementByKey(
		data?.national_international,
		'national',
		{},
	);

	const totalData = {
		female:
			(international?.gender?.female?.value || 0) +
			(national?.gender?.female?.value || 0),
		male:
			(international?.gender?.male?.value || 0) +
			(national?.gender?.male?.value || 0),
	};

	return (
		<div className="employees-indicators">
			<OneLineIndicator
				label="Total employees"
				className="total"
				value={employeesCount ? `${numberWithCommas(employeesCount)}` : 'N/A'}
			>
				{!!employeesCount && (
					<div className="sub-indicators total">
						<div>
							Female&nbsp;
							{getPercents(totalData.female, data.total, 0)}% (
							{numberWithCommas(totalData.female)}),&nbsp;
						</div>
						<div>
							Male&nbsp;
							{getPercents(totalData.male, data.total, 0)}% (
							{numberWithCommas(totalData.male)})&nbsp;
						</div>
					</div>
				)}
			</OneLineIndicator>
			<EmployeesLineIndicator keyName="contract_type" data={data} />
			<EmployeesLineIndicator keyName="national_international" data={data} />
		</div>
	);
};

export default EmployeesIndicators;
